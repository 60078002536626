import React from "react";
import { Link, graphql } from "gatsby";
import CardBlog from "../components/CardBlog";
// import CardBlogHorizontal from "../components/CardBlogHorizontal";
import Layout from "../components/layout";
import CardProject from "../components/CardProject";
import ContactsBlock from "../components/ContactsBlockAi";
import Testimonials from "../components/Testimonials";
import useInViewPort from "../hooks/useInViewPort";
import useResizeScreen from "../utils/resize";

// import SvgDivider2 from "../images/dividers/divider-2.inline.svg";

// import DecorationBlob6 from "../images/decorations/deco-blob-6.svg";
// import DecorationDots5 from "../images/decorations/deco-dots-5.svg";
import DecorationLine1 from "../images/decorations/deco-lines-1.svg";
import IconHorizontal from "../images/icons/interface/horizontal.svg";
import IconUserNetwork from "../images/icons/theme/general/user.svg";
import IconSketch from "../images/icons/interface/sketch.svg";
import IconSearchAnalytics from "../images/icons/theme/general/search.svg";
import ThinkpricingReview from "../images/logos/thinkpricing-review.svg";
import QoloReview from "../images/logos/qolo-review.svg";
import AgentadvanceReview from "../images/logos/agentadvance-review.svg";
// import useInViewPort from "../hooks/useInViewPort";
// import Helmet from "react-helmet";
import { SaasDesignJsonLd } from "../components/jsonld/SaasDesignJsonLd";
import {GatsbyImage} from "gatsby-plugin-image";
// import DecorationBlob17 from "../images/decorations/deco-blob-17.svg";

const AiDesignPage = ({ data, location: { pathname } }) => {
  const {isMobile, isTablet} = useResizeScreen();
  const ProjectCardsData = data.allDatoCmsProject.edges;
  const imageFirstSection = data.imageFirstSection.childImageSharp.gatsbyImageData;
  const [isInViewRef, isInView] = useInViewPort();

  return (
    <Layout
      isHeaderDark={true}
      headerBgClass="bg-primary-3"
      meta={{ seo: data.datoCmsPage.seo, path: pathname }}
    >
      <SaasDesignJsonLd />

      <div className="projects-page landing-page-1">

        <section className="o-hidden pt-5 py-1">
          <div className="container">
            <div className="row align-items-center min-vh-60">
              <div className="col-lg-7 text-center text-lg-left mb-4 mb-lg-0">
                <h1 className="display-4">
                  We're an AI Design Agency helping you to:
                </h1>
                <div className="my-5">
                    <ul className="list text-left">
                      <li className="list-item list-item-primary">
                        Create an intuitive user experience
                      </li>
                      <li className="list-item list-item-primary">
                        Increase users' trust in your AI product
                      </li>
                      <li className="list-item list-item-primary">
                        Scale your product without scaling the problems
                      </li>
                    </ul>
                  </div>
                {/* <div className="my-5">
                  <p className="lead">
                    Let us <span>skyrocket</span> your metrics through UX & Cognitive Science.
                  </p>
                </div> */}
                <Link
                  to="/contact-us"
                  id="freeconsultation"
                  className="btn btn-lg btn-primary"
                >
                  {!isMobile ? `Schedule Free Strategy Session` : `Free Strategy Session`}
                  {/* Contact Us */}
                </Link> 
              </div>
              <div className="col-lg-5 text-center">
                <div>
                  <GatsbyImage alt={'Image'} /* style={{width: 800}} */  image={imageFirstSection} loading="eager"/>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="bg-primary-3 has-divider text-light o-hidden">
          <div className="container layer-2">
            <div className="row">
              <div className="col-10 mx-auto text-left">
                <h1 className="display-4 pb-1">
                  SaaS UX:<br/> Let us Skyrocket your Product through Design & Cognitive Science
                </h1> */}

                {/* <p className="lead">
                  We’re dedicated to helping businesses of all sizes{" "}
                  <strong>transform their digital product</strong> and deliver a{" "}
                  <strong>stronger user experience. </strong>
                  Our cutting-edge, visionary UX design has helped some of the biggest brands in the
                  world, as well as up-and-coming companies, offer a{" "}
                  <strong>more powerful product.</strong>
                </p> */}
              {/* </div>
            </div>
          </div>
          <div className="divider">
            <SvgDivider2 />
          </div>

          <div className="decoration-wrapper d-none d-lg-block">
            <div
              className="decoration scale-1 "
              style={{ top: "30%", right: -30 }}
            >
              <DecorationBlob6 className="bg-yellow" />
            </div>

            <div className="decoration scale-1 left" style={{ bottom: 40 }}>
              <DecorationDots5 className="bg-primary" />
            </div>
          </div>
        </section> */}

        <section className="pb-1 pt-1">
          <div className="container">
            <div className="row">
              
              <div className="col-12 mb-3"><h2 className="h2 ml-2">AI UX Design Case Studies</h2>
                <p className="lead ml-2">We know what you’re going through. See for yourself:</p>
              </div>
              {ProjectCardsData.map(({ node }) => (
                <div className="col-12 col-md-6 d-flex" key={node.id}>
                  <CardProject cardData={node} type="vertical" />
                </div>
              ))}
            </div>
          </div>
        </section>
        
        <section className="pt-5 pb-0">
          <div className="container">
            <div className="row">
              <div className="col-12"><h2 className="h2 ml-2">Hear from our clients</h2></div>
            </div>
          </div>
        </section>
        <TestimonialBlock data={data} />

        <section className="bg-primary-alt pb-0 pb-md-5 pb-xl-7">
          <div className="decoration-wrapper d-none d-lg-block">
            <div
              className="decoration scale-1"
              style={{ top: -120, right: "15%", transform: "rotate(55.54deg)" }}
            >
              <DecorationLine1 className="bg-yellow" />
            </div>
          </div>

          <div className="container pb-6">
            <div className="row">
              <div className="col-12 row align-items-center mb-4">
                <div className="col-md-8">
                  <h2 className="h2">AI UX Design Articles</h2>
                </div>
              </div>

              {
                [
                  {
                    node: {
                      title:
                        "Top 20 AI Tools for Designers & Content Creators",
                      cardDescription:
                        "Discover the top 20 tools that can help streamline your design process and bring your ideas to life...",
                      cardImage: {
                        url:
                          "https://www.datocms-assets.com/16499/1674569810-ai-tools-for-designers.png?auto=format"
                      },
                      category: { name: "AI UX" },
                      id: "top-20-ai-tools-for-designers",
                      slug: "top-20-ai-tools-for-designers"
                    }
                  },
                  {
                    node: {
                      title:
                        "AI UX design: A New Way Of Designing",
                      cardDescription:
                        "This is a complete guide to AI UX design. Discover AI-infused UX, its impact on the design process, and how to design AI-powered products.",
                      cardImage: {
                        url:
                          "https://www.datocms-assets.com/16499/1659426304-ai-in-ux-min.png?auto=format"
                      },
                      category: { name: "AI Design" },
                      id: "ai-ux-design",
                      slug: "ai-ux-design"
                    }
                  }/* ,
                  {
                    node: {
                      title:
                        "UX and SaaS Monetization",
                      cardDescription:
                        "This article touches on the importance of great UX when it comes to making money off your app.",
                      cardImage: {
                        url:
                          "https://www.datocms-assets.com/16499/1608620747-saas-1-min.png?auto=format"
                      },
                      category: { name: "SaaS Strategy" },
                      id: "ux-and-saas",
                      slug: "ux-and-saas"
                    }
                  } */

                ]

                  .map(({ node }) => (
                    <div className="col-md-6 col-lg-4" key={node.id}>
                      <CardBlog key={node.id} cardData={node} />
                    </div>
                  ))
              }
            </div>
          </div>
        </section>

        {/*<section className="mb-7 o-hidden pos-r">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <a href="/">
                  <div className="card card-body bg-primary border-0 text-light o-hidden">
                    <div className="h4 text-center mb-0 py-2">Download Case Studies as PDF</div>

                    <div className="decoration-wrapper d-none d-lg-block">
                      <div className="decoration" style={{ right: 40, bottom: -60 }}>
                        <DecorationBlob17 className="bg-primary-3" />
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>*/}
      </div>

      <div ref={isInViewRef}>
        <section className="fifth-section bg-primary">
          {/* <div className="divider">
            <Divider1 />
          </div> */}

          <div className="container text-light">
            <div className="row">
              <div className="col-12">
                <h3 className="h1 text-center mb-6 col-12 col-md-7 mx-auto">
                  How are we different from other AI design agencies?
                </h3>
              </div>
            </div>
            <div className="row blocks">
              <div className="col-md-6 col-lg-6 mb-6 pr-2 pr-md-4">
                <div className="">
                  <div>
                    <IconHorizontal className="injected-svg icon icon-md bg-white" />
                  </div>
                  <p className="h5">Deadlines</p>
                  <p className="lead">
                    We adhere to deadlines without needing you to micromanage.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6 pl-2 pl-md-4">
                <div className="">
                  <div>
                    <IconUserNetwork className="injected-svg icon icon-md bg-white" />
                  </div>
                  <p className="h5">Data-driven</p>
                  <p className="lead">
                    Our design puts users first. Not just by means of just it does, but by actually following the proper UX process.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6 pr-2 pr-md-4">
                <div className="">
                  <div>
                    <IconSketch className="injected-svg icon icon-md bg-white" />
                  </div>
                  <p className="h5">Complex Products</p>
                  <p className="lead">
                    We deal with data heavy and complex AI products.
                    The kind of challenges you won't find the answer on a medium blog.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 pl-2 pl-md-4">
                <div className="">
                  <div>
                    <IconSearchAnalytics className="injected-svg icon icon-md bg-white" />
                  </div>
                  <p className="h5">Behavior Design</p>
                  <p className="lead">
                    Through behavioral design, we increase engagement and retention of your AI platform
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      


      <ContactsBlock isInViewProp={true} />

    </Layout>
  );
};

const TestimonialBlock = ({ data }) => {
  const ThinkpricingAvatar = data.ThinkpricingAvatar.childImageSharp.gatsbyImageData;
  const QoloAvatar = data.QoloAvatar.childImageSharp.gatsbyImageData;
  const AgentadvanceAvatar = data.AgentadvanceAvatar.childImageSharp.gatsbyImageData;

  return (
    <Testimonials
      className="pt-0 mt-n2"
      title=""
      testimonials={[
        {
          content: `The final UX design meets all our requirements and looks great.
                                    The team is efficient and to the point, impressively able to understand design
                                    needs and integrate feedback.`,
          imageFixed: ThinkpricingAvatar,
          logo: (
            <ThinkpricingReview
              className="align-items-left"
              style={{ width: "178px" }}
            />
          )
        },
        {
          content: `The MVP received positive feedback from the internal team.
                                    Adam Fard UX Studio creates high-quality SaaS designs that meet the needs of the client.
                                    The team is hard-working, innovative, and driven.`,
          imageFixed: QoloAvatar,
          logo: (
            <QoloReview
              className="align-items-left"
              style={{ width: "148px" }}
            />
          )
        },
        {
          content: `Adam Fard UX Studio gave us the hands-on feeling of
                                    a small shop and the quality and professionalism of a big shop.`,
          imageFixed: AgentadvanceAvatar,
          logo: (
            <AgentadvanceReview
              className="align-items-left"
              style={{ width: "143px" }}
            />
          )
        }
      ]}
    />
  );
};

export default AiDesignPage;

export const query = graphql`
  query AiDesignPageQuery {
    imageFirstSection: file(
      relativePath: { eq: "photos/AI-Design-Agency-UX.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1200)
      }
    }

    ThinkpricingAvatar: file(relativePath: { eq: "photos/think-avatar.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 100, height: 100)
      }
    }

    QoloAvatar: file(relativePath: { eq: "photos/qolo-avatar.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 100, height: 100)
      }
    }

    AgentadvanceAvatar: file(relativePath: { eq: "photos/agent-avatar.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 100, height: 100)
      }
    }

    datoCmsPage(slug: { eq: "ai-design-agency" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }

    allDatoCmsProject(
      limit: 4
      filter: { industryTag2: { eq: "AI" } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          cardImage {
            gatsbyImageData(width: 1600,  layout: CONSTRAINED)
          }
          logo {
            url
          }
          cardColor {
            hex
          }

          cardDescription
          categoryProject
          industry

          slug
          id
          secretProject
        }
      }
    }
  }
`;
